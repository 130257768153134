import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Step Up Jumps 4×2/leg`}</p>
    <p>{`Back Squat 4×2\\@90%1RM`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`2 Lap Run`}</p>
    <p>{`40 Squats`}</p>
    <p>{`30 KBS (53/35)`}</p>
    <p>{`20 V Ups`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The signup sheet for Memorial Day Murph is up next to the white
board at the gym.  We’ll have heats starting every :30 beginning at
9:00am.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      